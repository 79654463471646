import React from "react";
import styles from "../../styles/slider.module.scss";
import { ButtonComponent } from "../commons/Button.component";
import { SliderItemWithActions } from "./Slider.component";

interface Props {
  index: number;
  item: SliderItemWithActions;
  openModal: () => void;
}

export const StaticSlideComponent: React.FC<Props> = ({
  index,
  item,
  openModal,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <div className={`${styles.slide} ${styles["slide--static"]}`}>
        <div className={styles.content}>
          <div className={styles.bg2}>
            <img src={item.asset_mobile} alt={item.name} />
          </div>
          <div className={`${styles.bg4} ${styles[`bg4-${index}`]}`}>
            <div className={styles.btn}>
              <ButtonComponent
                onClick={openModal}
                style="banner"
                className={item.button_color === "blue" ? styles.btn_blue : ""}
              >
                {item.button_text}
              </ButtonComponent>
            </div>
          </div>
          <div className={styles.bg5}>
            <img src={item.asset_desktop} alt={item.name} />
          </div>
        </div>
      </div>
    </div>
  );
};
