import React from "react";
import styles from "../../styles/button.module.scss";

interface Props {
  style: "primary" | "secondary" | "banner";
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
}

export const ButtonComponent: React.FC<Props> = ({
  style,
  onClick,
  children,
  className,
}) => {
  return (
    <button
      className={`${styles.button} ${styles[`button--${style}`]} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
